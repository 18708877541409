import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {I18nService} from "@shared/core/services/i18n.service";

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService,
              private i18n: I18nService) {
  }

  onSuccess(detail: string, params: object = {}) {
    this.messageService.add({
      severity: 'success',
      summary: this.i18n.translatePhrase('NOTIFICATION.SUCCESS'),
      detail: this.i18n.translatePhrase(detail, params)
    });
  }

  onError(detail: string, params: object = {}) {
    this.messageService.add({
      severity: 'error',
      summary: this.i18n.translatePhrase('NOTIFICATION.ERROR'),
      detail: this.i18n.translatePhrase(detail, params)
    });
  }

  onWarning(detail: string, params: object = {}) {
    this.messageService.add({
      severity: 'warn',
      summary: this.i18n.translatePhrase('NOTIFICATION.WARNING'),
      detail: this.i18n.translatePhrase(detail, params)
    });
  }
}
